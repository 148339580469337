export const regions = [
  { id: 1, name: 'Київська' },
  { id: 2, name: 'Вінницька' },
  { id: 3, name: 'Волинська' },
  { id: 4, name: 'Дніпропетровська' },
  { id: 5, name: 'Донецька' },
  { id: 6, name: 'Житомирська' },
  { id: 7, name: 'Закарпатська' },
  { id: 8, name: 'Запорізька' },
  { id: 9, name: 'Кіровоградська' },
  { id: 10, name: 'Луганська' },
  { id: 11, name: 'Львівська' },
  { id: 12, name: 'Миколаївська' },
  { id: 13, name: 'Одеська' },
  { id: 14, name: 'Полтавська' },
  { id: 15, name: 'Рівненська' },
  { id: 16, name: 'Сумська' },
  { id: 17, name: 'Полтавська' },
  { id: 18, name: 'Тернопільська' },
  { id: 19, name: 'Харківська' },
  { id: 20, name: 'Херсонська' },
  { id: 21, name: 'Хмельницька' },
  { id: 22, name: 'Черкаська' },
  { id: 23, name: 'Чернівецька' },
  { id: 24, name: 'Чернігівська' },
];

export const statuses = [
  { id: 1, name: 'від забудовника' },
  { id: 2, name: 'вторинний ринок' },
];

export const ceilings = [
  { id: 1, name: 'до 3м' },
  { id: 2, name: 'більше 3м' },
];
